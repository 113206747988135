.cmp-atendimento__header .MuiTabs-flexContainer {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    justify-content: space-between;
    gap: 1rem;
}

.cmp-atendimento__header {
    background-color: transparent;
    border-radius: 8px 8px 0 0;
    width: 100%;
}

.cmp-atendimento__header .MuiTab-wrapper {
    text-transform: capitalize;
    font-weight: 600;
}

.cmp-tickers__close-every-ticket--disabled {
    display: none !important;
}

.cmp-tickers__close-every-ticket {
    display: flex !important;
}

.cmp-tickets__attend-left-button  {
    border-top: none !important;
    border-radius: 0 0 8px 8px !important; 
}

.cmp-tickets__rigth-container {
    width: 360px;
}

.cmp-tickets__attend-left-button .MuiTabs-root {
    padding: 0 1rem;
}

#drawer-container {
    gap: 0.5rem;
}

.cmp-drawer--closed {
    gap: 0 !important;
}

.cmp-tickets__filter-modal .MuiFormControlLabel-label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
}

.cmp-tickers__close-every-ticket .MuiOutlinedInput-root {
    font-size: 12px;
}

.cmp-contact-drawer__close-icon {
    background: transparent;
    display: flex;
    border-radius: 4px;
    cursor: pointer;
    width: 32px;
    justify-content: center;
    align-items: center;
    height: 32px;
}

.cmp-contact-drawer__close-icon svg {
    width: 1rem;
    height: 1rem;
    transition: .3s;
}

.cmp-message-chat__contianer {
    display: flex;
    padding: 1rem;
    font-family: "Poppins";
    font-weight: 400;
    color: black;
}
