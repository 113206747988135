
.cmp-internchat--open > button {
    display: none;
}
.cmp-internchat--mobile > button {
    display: none;
}
@media screen and (max-width: 990px) {
    .cmp-internchat--mobile {
        display: none !important;
    }

    .cmp-internchat--open {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100vw;
        height: 100vh;
        max-height: 100%;
    }
    .cmp-internchat--open > button{
        position: absolute;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0.8rem;
        min-width: 25px;
        padding: 0;
        height: 25px;
        min-height: 25px;
        right: 1rem;
    }
}