.cmp-dashboard__raw-data {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.cmp-dashboard__raw-data-item {
    padding: 1rem;
    border-radius: 4px;
    width: 18%;
    display: flex;
    transition: .3s;
    gap: 0.5rem;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.cmp-dashboard__raw-data-item--first {
    color: white;
    padding: 1rem;
    transition: .3s;
    border-radius: 4px;
    width: 18%;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.cmp-dashboard__raw-data-item h2, .cmp-dashboard__raw-data-item--first h2 {
    font-size: 1rem;
    font-family: "Poppins";
}

.cmp-dashboard__raw-data-item span, .cmp-dashboard__raw-data-item--first span {
    font-size: 1.5rem;
    font-family: "Poppins";
}

.cmp-dashboard__raw-data-number {
    display: flex;
    gap: 1.3rem;
    align-items: center;
    position: relative;
}

.cmp-dashboard__online-circle {
    background-color: #0ad122;
    width: 13px;
    border: 1px solid white;
    height: 13px;
    border-radius: 100%;
    position: absolute;
    top: 1rem;
    left: 2.2rem;
}

.cmp-dashboard__raw-data-number .MuiSvgIcon-root {
    font-size: 2rem;
}

.cmp-dashboard__graphs {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;
}

.cmp-dashboard__graphs-box--seller-rank   {
    padding: 1rem 1rem 2rem 1rem;
}

.cmp-dashboard__graphs-box--first {
    border-radius: 4px;
    width: 100%;
    padding: 1rem 1rem 2rem 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.cmp-dashboard__graphs-box {
    background-color: white;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.cmp-dashboard__graphs-box--left {
    width: 18%;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.cmp-dashboard__graphs-box--seller-rank,
.cmp-dashboard__graphs-box--pie {
    border-radius: 4px;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.cmp-dashboard__filter-container {
    display: flex;
    width: 100%;
    padding: 4rem 1rem 0rem 1rem;
}

.cmp-dashboard__plus-info-container {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.cmp-dashboard__plus-info-container {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.cmp-dashboard__plus-info-container .MuiPaper-root {
    margin-top: 2rem;
    width: 50%;
}

.cmp-dashboard__attendants-table {
    margin-top: 2rem;
}

.cmp-dashboard__attendants-table thead th {
    font-family: "Poppins";
    font-weight: 400 !important;
}

iframe {
    display: none;
}

@media screen and (max-width: 958px) {
    .cmp-dashboard__raw-data {
        flex-direction: column;
        gap: 2rem;
    }

    .cmp-dashboard__raw-data-item--first  {
        width: 100%;
    }

    .cmp-dashboard__raw-data-item {
        width: 100%;
    }

    .cmp-dashboard__graphs {
        flex-direction: column;
    }

    .cmp-dashboard__graphs-box--left {
        width: 100%;
    }

    .cmp-dashboard__graphs-new-container {
        width: 100% !important;
        margin-top: 2rem;
    }
}